.news-titles-bullet-line {
    overflow: hidden; /* Hide overflow */
    white-space: nowrap; /* Prevent wrapping */
    background-color: red;
    display: flex; /* Use flex to align items */
    align-items: center; /* Center items vertically */
    height: 5vh;
    margin-top:3vh;
}

.bullet {
    display: inline-flex; /* Display titles in a single row */
    animation: ticker 40s linear infinite; /* Animation for scrolling */
    align-items: center; /* Center titles vertically within the bullet */
}

@keyframes ticker {
    from {
        transform: translateX(0); /* Start position */
    }
    to {
        transform: translateX(calc(-100%)); /* End position (scroll left) */
    }
}

.title {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; /* Center text vertically */
    background-color: yellow;
    border-radius: 0 20px 20px 0;
    z-index: 1;
    padding: 0 1vw; /* Add padding for spacing */
}

.title h6 {
    font-size: 1.2rem;
    font-weight: 800;
    margin: 0; 
    color: black;
}

.bullet h6 {
    font-size: 1.2rem; /* Adjust font size if needed */
    margin: 0; /* Remove margin */
}

.ticker-title {
    display: inline-flex;
    justify-content: center;
    align-items: center; /* Center text vertically */
    padding: 0 10px; /* Adjust padding as needed */
    margin-right: 10px; /* Add margin between titles */
    text-decoration: none;
    color: #ffffff;
    /* margin-top: 1.8vh; */
}

/* Dark Mode Styles */
.news-titles-bullet-line.dark-mode {
    background-color: #fb0000;
    color: #e0e0e0;
}

.news-titles-bullet-line.dark-mode .title {
    /* background-color: #d1ff02; */
    color: #e0e0e0;
}

.news-titles-bullet-line.dark-mode .title h6 {
    color: #000000;
}

.news-titles-bullet-line.dark-mode .ticker-title h6 {
    color: #000000;
}

@media (max-width: 500px) {
    .news-titles-bullet-line {
        overflow: hidden; /* Hide overflow */
        white-space: nowrap; /* Prevent wrapping */
        background-color: red;
        display: flex; /* Use flex to align items */
        align-items: center; /* Center items vertically */
        height: 4vh;
        margin-top:3vh;
    }
    
    .bullet {
        display: inline-flex; /* Display titles in a single row */
        animation: ticker 40s linear infinite; /* Animation for scrolling */
        align-items: center; /* Center titles vertically within the bullet */
    }
    
    @keyframes ticker {
        from {
            transform: translateX(0); /* Start position */
        }
        to {
            transform: translateX(calc(-100%)); /* End position (scroll left) */
        }
    }
    
    .title {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center; /* Center text vertically */
        background-color: yellow;
        border-radius: 0 20px 20px 0;
        z-index: 1;
        padding: 0 1vw; /* Add padding for spacing */
    }
    
    .title h6 {
        font-size: 1rem;
        font-weight: 800;
        margin: 0; 
        color: black;
    }
    
    .bullet h6 {
        font-size: 1rem; /* Adjust font size if needed */
        margin: 0; /* Remove margin */
    }
    
  }
  