/* General Styles */
.footer {
  /* background: linear-gradient(90deg, #ff8a00, #e52e71); */
  color: black;
  padding: 2rem 0;
  text-align: center;
  position: relative;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.footer-logo img {
  height: 40px;
}

.footer-links {
  display: flex;
  gap: 1rem;
}

.footer-link {
  text-decoration: none;
  color: black;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.footer-link:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.footer-social {
  display: flex;
  gap: 1rem;
}

.footer-icon {
  font-size: 2.5rem;
  color: black;
  transition: transform 0.3s ease;
}

.footer-icon:hover {
  transform: scale(1.2);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    gap: 1.5rem;
  }

  .footer-links {
    flex-direction: column;
  }
}

.footer-developer {
  cursor: pointer;
  text-decoration: underline;
  color: black;
  /* Adjust color as needed */
}

.footer-developer:hover {
  color: #007bff; /* Change color on hover */
}

/* Dark Mode Styles */
body.dark-mode .footer {
  background: black;
  color: #e0e0e0;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.5);
}

body.dark-mode .footer-link {
  color: #e0e0e0;
}

body.dark-mode .footer-link:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

body.dark-mode .footer-icon {
  color: #e0e0e0;
}

body.dark-mode .footer-developer {
  color: #e0e0e0;
}

body.dark-mode .footer-developer:hover {
  color: #177fef;
}
