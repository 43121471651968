/* AboutPage.css */

.about-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #fff;
    transition: background-color 0.3s, color 0.3s;
}

.about-container.dark-mode {
    color: #ddd;
    background-color: #333;
}

.about-container h1,
.about-container h2 {
    color: inherit;
}

.about-container p {
    margin-bottom: 16px;
}
