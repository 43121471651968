.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.dark-mode {
  background-color: #000000;
  color: #f4f4f4;
}

/* CSS for the Breaking News Banner */
.breaking-news {
  color: #000000;
  padding: 10px;
  text-align: center;
  font-size: 1.5rem;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.box-with-animation h1 {
  margin: 0;
  padding: 0 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  font-weight: bold;
}

/* News Card Styles */
.news-block {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
  transition: transform 0.2s ease;
}

.news-block:hover {
  /* transform: scale(1.01); Adjust the scale factor as needed */
}

.news-card {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  overflow: hidden;
  transition: transform 0.2s ease;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  margin: 10px;
  background: #fff;
}

.news-card:hover {
  background-color: #d3cd9e;
}

.dark-mode .news-card:hover {
  background-color: #878787;
}

.dark-mode .news-card {
  background: #1e1e1e;
  box-shadow: rgba(255, 255, 255, 0.25) 0px 54px 55px, rgba(64, 64, 64, 0.12) 0px -12px 30px, rgba(65, 61, 61, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(94, 93, 93, 0.09) 0px -3px 5px;
}

.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.watermark-container {
  position: relative;
}

.watermark-overlay {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #ff0000;
  padding: 5px;
  border-radius: 5px;
  font-size: 1rem;
}

.card-details {
  padding: 20px;
}

.card-title {
  font-size: 1.25rem;
  margin: 0 0 10px;
}

.card-date,
.card-location {
  font-size: 0.875rem;
  color: #666;
}

.dark-mode .card-date,
.dark-mode .card-location {
  color: #aaa;
}

.read-more {
  color: #ff3333;
  font-weight: bold;
}

.dark-mode .read-more {
  color: #ff9999;
}

/* Skeleton Loader Styles */
.skeleton {
  background: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin: 10px;
  max-width: 400px;
}

.skeleton-image {
  width: 100%;
  height: 200px;
  background: #d4d4d4;
}

.skeleton-details {
  padding: 20px;
}

.skeleton-title {
  width: 100%;
  height: 20px;
  background: #d4d4d4;
  margin-bottom: 10px;
}

.skeleton-text {
  height: 14px;
  background: #d4d4d4;
  margin-bottom: 10px;
}

.skeleton-text.short {
  width: 80%;
}

.skeleton-text.long {
  width: 80%;
}

.skeleton-text.small {
  width: 100%;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .news-block {
    flex-direction: column;
  }

  .news-card,
  .skeleton {
    max-width: 90%;
    margin-left: 1.5rem;
  }

  .news-card,
  .skeleton {
    padding: 1rem;
  }
}

@media (min-width: 769px) {
  .news-block {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
  }
}
