/* General Styles */
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.main-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  height: 10vh;
  position:fixed;
  width: 100%;
  top: 0;
  left: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(255, 255, 255);
  border-bottom: 4px black solid;
  z-index: 1000; /* Ensure the navbar is on top of other elements */
}

.logo img {
  height: 8vh;
}

.menu-link {
  display: flex;
  align-items: center;
  margin-top: 0px;
  transition: transform 0.3s ease-in-out;
}

.menu-link.active {
  transform: translateX(0);
}

.navbar-lists {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0 auto; /* Centering the navbar lists */
}

.navbar-link {
  text-decoration: none;
  color: rgb(0, 0, 0);
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.navbar-link:hover {
  /* background-color: rgba(0, 0, 0, 0.1); */
}

.mobile-navbar-btn {
  display: none;
}

.theme-toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  font-size: 1rem;
  margin-left: auto;
}

.theme-toggle-button:hover {
  color: #177fef;
}

.social-icons {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-left: 2rem;
}

.social-icon {
  font-size: 1.5rem;
  /* color: #333; */
  transition: color 0.3s ease;
  position: relative;
}

.social-icon:hover {
  color: #177fef;
}

.header-weather {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-right: 1rem;
}

.weather-icon {
  font-size: 1.5rem;
}

.weather-location,
.weather-temperature {
  font-size: 1rem;
}
body.dark-mode .navbar-lists {
  background-color: black;;
}
body.dark-mode .social-icons {
  background-color: rgb(0, 0, 0);;
}
body.dark-mode .mobile-menu-link {
  background-color: black;;
}
/* Responsive Styles */
@media (max-width: 900px) {
  .mobile-navbar-btn {
    display: block;
    font-size: 2rem;
    color: rgb(0, 0, 0);
    cursor: pointer;
  }
  body.dark-mode .mobile-navbar-btn {
    display: block;
    font-size: 2rem;
    color: rgb(255, 254, 254);
    cursor: pointer;
  }
 

  .menu-link {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    z-index: 2;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateX(-100%);
  }

  body.dark-mode .menu-link.active {
    transform: translateX(0);
    background-color: #000000;
  }
  .menu-link.active {
    transform: translateX(0);
    background-color: #ffffff;
  }

  .navbar-lists {
    flex-direction: column;
    width: 100%;
    text-align: center;
  }

  .navbar-link {
    width: 100%;
  }

  .social-icons {
    justify-content: center;
    margin-top: 1rem;
  }

  .header-weather {
    justify-content: center;
    margin: 1rem 0;
  }
}



body.dark-mode .main-nav {
  background-color: #000000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  border-bottom: 4px #e0e0e0 solid;
}

body.dark-mode .navbar-link {
  color: #e0e0e0;
}


/* Add these styles to NavigationBar.css */

/* Add these styles to NavigationBar.css */

.svg-wrapper {
  margin-top: 0;
  position: relative;
  width: 100px;
  height: 40px;
  display: inline-block;
  border-radius: 3px;
  margin-left: 5px;
  margin-right: 5px;

}

#shape {
  stroke-width: 6px;
  fill: transparent;
  stroke: #2fa4de;
  stroke-dasharray: 95 300;
  stroke-dashoffset: 250;
  transition: 1s all ease;
  color:#ff0000;

}
/* NavigationBar.css */



.svg-wrapper text {
  fill: rgb(0, 0, 0); /* This will change the color of the text inside the SVGs to red */
}

body.dark-mode .svg-wrapper text {
  fill: rgb(255, 255, 255); /* This will change the color of the text inside the SVGs to red */
}





#text {
  text-align: center;
  font-weight: 100;
  font-size: 1.1em;
}

body.dark-mode .text2 {
  color:white;
}

.svg-wrapper:hover #shape {
  stroke-dasharray: 50 0;
  stroke-width: 3px;
  stroke-dashoffset: 0;
  stroke: #06D6A0;
}

/* Other styles you have in NavigationBar.css */


/* Other styles you have in NavigationBar.css */



body.dark-mode .theme-toggle-button {
  color: #e0e0e0;
}
body.dark-mode .theme-toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #ffffff;
  font-size: 1rem;
  margin-left: auto;
}


body.dark-mode .social-icon {
  color: #e0e0e0;
}

body.dark-mode .social-icon:hover {
  color: #177fef;
}

/* Glow Effect for Social Media Icons */
.glow-on-hover {
  display: inline-block;
  font-size: larger;
  position: relative;
  cursor: pointer;
  transition: color 0.3s ease;
}

.glow-on-hover:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  background-size: 400%;
  border-radius: 50%;
  filter: blur(5px);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}

.social-icons .glow-on-hover:before {
  animation: glowing 20s linear infinite;
}
