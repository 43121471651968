

.img-container {
  position: relative;
  perspective: 500px;
  transform-style: preserve-3d;
  height: 60vh;
  width: 100%;
  
}

.box {
  /* box-shadow: 0 0 10px rgb(0 0 0 / 30%); */
  border-radius: 25px;
  overflow: hidden;
  position: absolute;
  width: 90vw;
  height: 60vh;
  top: 50%;
  transition: 800ms ease-in-out;
}

.box img {
  width: 90%;
  height: 100%;
}

.box:first-of-type {
  z-index: 5;
  opacity: 0;
  left: 15%;
  transform: translate(-50%, -50%) rotateY(-10deg);
}

.box:nth-of-type(2) {
  opacity: 1;
  left: 20%;
  transform: translate(-50%, -50%) rotateY(-10deg);
  z-index: 5;
}

.box:nth-of-type(3) {
  left: 25%;
  opacity: 0.75;
  z-index: 10;
  transform: translate(-50%, -50%) rotateY(-10deg) translateZ(-50px);
}

.box:nth-of-type(4) {
  z-index: 5;
  opacity: 0.5;
  left: 30%;
  transform: translate(-50%, -50%) rotateY(-10deg) translateZ(-100px);
}

.box:nth-of-type(5) {
  z-index: 2;
  opacity: 0.25;
  left: 35%;
  transform: translate(-50%, -50%) rotateY(-10deg) translateZ(-150px);
}

.box:nth-of-type(6) {
  z-index: 2;
  opacity: 0.0;
  left: 40%;
  transform: translate(-50%, -50%) rotateY(-10deg) translateZ(-200px);
}

/* Medium devices (tablets, 768px and up) */
@media (min-width:601px)and (max-width: 768px) {
  .img-container {
    width: 100%;
    height: 40vh;
  }
  .box {
    width: 90vw;
    height: 40vh;
  }
  .box:first-of-type {
    left: 10%;
  }
  .box:nth-of-type(2) {
    left: 15%;
  }
  .box:nth-of-type(3) {
    left: 20%;
  }
  .box:nth-of-type(4) {
    left: 25%;
  }
  .box:nth-of-type(5) {
    left: 30%;
  }
  .box:nth-of-type(6) {
    left: 35%;
  }
}

@media (max-width: 600px) {
  .img-container {
    width: 100%;
    height: 30vh;
  }
  .box {
    width: 90vw;
    height: 30vh;
  }
  .box:first-of-type {
    left: 10%;
  }
  .box:nth-of-type(2) {
    left: 15%;
  }
  .box:nth-of-type(3) {
    left: 20%;
  }
  .box:nth-of-type(4) {
    left: 25%;
  }
  .box:nth-of-type(5) {
    left: 30%;
  }
  .box:nth-of-type(6) {
    left: 35%;
  }
}
