.ad-session {
  max-width: 100%;
}

.ad-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh; /* Default height for larger screens */
  width: 100%;
  object-fit:contain;
}

@media (max-width: 900px) and (min-width: 500px) {
  .ad-img {
    max-height: 40vh; 
  }
}

@media (max-width: 500px) {
  .ad-img {
    max-height: 30vh; /* Adjusted height for smaller screens */
  }
}
